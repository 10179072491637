/*
 * Page dédiée a l'affichages des statistique Entreprise ou agence de l'utilisateur client connecté (en fonction de son rôle)
 */
import { Container, Heading, Accordion } from '@chakra-ui/react'
import { ConditionalConnect } from '../../auth/ConditionalConnect'
import { Login } from '../Authentification/Login'
import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContextProvider'
import {
    BlocTroisStats,
    BlocTroisStatsAccordeon,
} from '../../components/BlocTroisStats'
import {
    Agence,
    CategorieEquipement,
    Entreprise,
    Equipement,
    Role,
    Utilisateur,
} from '../../model'
import {
    GetAllAgencesForTargetEntreprise,
    GetTargetAgence,
} from '../../firebaseManager/Communicator/AgenceCommunicator'
import { UtilisateurContext } from '../../auth/UtilisateurContextProvider'
import { GetAllSharedEquipementsForAgence } from '../../firebaseManager/Communicator/EquipementCommunicator'
import {
    VeloPanel,
    VeloPanelEmpty,
    VeloPanelLoading,
} from '../../components/VeloPanel'
import { GetAllUtilisateursTargetAgence } from '../../firebaseManager/Communicator/UtilisateurCommunicator'
import { GetTargetEntreprise } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { EntrepriseContext } from '../../auth/EntrepriseContextProvider'

export const MyEnterpriseEquipments = () => {
    const authentification = useContext(AuthContext)
    const EntrepriseFromContext = useContext(EntrepriseContext)
    const actualUser = useContext(UtilisateurContext)
    const [searchParams] = useSearchParams()

    const [targetEntreprise, setTargetEntreprise] = useState<string | null>()
    const [allTargetEntrepriseAgences, setAllTargetEntrepriseAgences] =
        useState<Agence[] | null>()
    const [canViewStat, setCanViewStat] = useState<boolean>(false)

    const [targetAgence, setTargetAgence] = useState<string | null>()
    const [veloDeLAgence, setVeloDeLAgence] = useState<Equipement[] | null>()
    const [veloDeLAgenceLoaded, setVeloDeLAgenceLoaded] =
        useState<boolean>(false)
    const [entrepriseParentdeAgence, setEntrepriseParentdeAgence] =
        useState<Entreprise | null>()
    const [allTargetAgenceUsers, setAllTargetAgenceUsers] = useState<
        Utilisateur[] | null
    >()

    function RefreshAlAgenceForEntreprise(id_entreprise: string) {
        GetAllAgencesForTargetEntreprise(id_entreprise!)
            .then((result) => {
                setAllTargetEntrepriseAgences(result)
            })
            .catch((e) => setAllTargetEntrepriseAgences(null))
    }

    function RefreshAllSharedEquipementForAgence(id_agence: string) {
        GetAllSharedEquipementsForAgence(id_agence)
            .then((result) => {
                setVeloDeLAgence(
                    result.filter(
                        (equipement) =>
                            equipement.categorie === CategorieEquipement.Velo
                    )
                )
                setVeloDeLAgenceLoaded(true)
            })
            .catch((e) => {
                setVeloDeLAgence(null)
                setVeloDeLAgenceLoaded(true)
            })
    }

    function RefreshAllUsersForAgence(id_agence: string) {
        GetAllUtilisateursTargetAgence(id_agence)
            .then((result) => {
                setAllTargetAgenceUsers(result)
            })
            .catch((e) => setAllTargetAgenceUsers(null))
    }

    function GetEntrepriseObjectFromAgence(id_agence: string) {
        GetTargetAgence(id_agence)
            .then((result: Agence | undefined) => {
                if (result && result.entrepriseAppartenance) {
                    GetTargetEntreprise(result.entrepriseAppartenance)
                        .then((result) => {
                            console.log('dededededede')
                            console.log(result?.id)
                            setEntrepriseParentdeAgence(result)
                        })
                        .catch((e) => setEntrepriseParentdeAgence(null))
                }
            })
            .catch((e) => setEntrepriseParentdeAgence(null))
    }

    function CollectDataForManagingEntreprise(id_entreprise: string) {
        setTargetEntreprise(id_entreprise)
        RefreshAlAgenceForEntreprise(id_entreprise)
    }
    function CollectDataForManagingAgence(id_agence: string) {
        setTargetAgence(id_agence)
        RefreshAllSharedEquipementForAgence(id_agence)
        RefreshAllUsersForAgence(id_agence)
        GetEntrepriseObjectFromAgence(id_agence)
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            if (actualUser != null) {
                if (actualUser.role === Role.Admin) {
                    setCanViewStat(true)
                    const id_entreprise: string =
                        searchParams.get('id_entreprise')!
                    const id_agence: string = searchParams.get('id_agence')!
                    // simulation de manager entreprise
                    if (id_entreprise) {
                        CollectDataForManagingEntreprise(id_entreprise)
                    }
                    // simulation de manager agence
                    if (id_agence) {
                        CollectDataForManagingAgence(id_agence)
                    }
                }
                if (actualUser.role === Role.ManagerEntreprise) {
                    setCanViewStat(true)
                    if (actualUser.entrepriseAppartenance) {
                        CollectDataForManagingEntreprise(
                            actualUser.entrepriseAppartenance
                        )
                    }
                }
                if (actualUser.role === Role.ManagerAgence) {
                    setCanViewStat(true)
                    if (actualUser.agenceAppartenance) {
                        setEntrepriseParentdeAgence(EntrepriseFromContext)

                        CollectDataForManagingAgence(
                            actualUser.agenceAppartenance
                        )
                    }
                }
                if (
                    actualUser.role === Role.ClientFinal &&
                    actualUser.accessVeloAgence
                ) {
                    setCanViewStat(false)
                    if (actualUser.agenceAppartenance) {
                        setTargetAgence(actualUser.agenceAppartenance)
                        RefreshAllSharedEquipementForAgence(
                            actualUser.agenceAppartenance
                        )
                    }
                }
            }
        }
    }, [authentification, actualUser])

    return (
        <>
            <ConditionalConnect showIfConnected>
                <Container maxW="container.md">
                    {targetEntreprise && canViewStat && (
                        <>
                            <Heading fontSize="3xl" mt={'20px'} mb={'10px'}>
                                Statistiques de mon entreprise
                            </Heading>
                            <BlocTroisStats entrepriseId={targetEntreprise} />
                            {allTargetEntrepriseAgences &&
                                allTargetEntrepriseAgences?.length > 0 && (
                                    <>
                                        <Heading
                                            fontSize="2xl"
                                            mt={'20px'}
                                            mb={'10px'}
                                        >
                                            Statistiques des agences
                                        </Heading>
                                        <Accordion allowMultiple mt={'30px'}>
                                            {allTargetEntrepriseAgences?.map(
                                                function (agence, idx) {
                                                    return (
                                                        <BlocTroisStatsAccordeon
                                                            key={idx}
                                                            agenceId={agence.id}
                                                            entrepriseId={
                                                                targetEntreprise
                                                            }
                                                            titre={agence.nom}
                                                        />
                                                    )
                                                }
                                            )}
                                        </Accordion>
                                    </>
                                )}
                        </>
                    )}
                    {targetAgence && (
                        <>
                            {canViewStat && entrepriseParentdeAgence && (
                                <>
                                    <Heading
                                        fontSize="3xl"
                                        mt={'20px'}
                                        mb={'10px'}
                                    >
                                        Statistiques de mon Agence
                                    </Heading>

                                    <BlocTroisStats
                                        agenceId={targetAgence}
                                        entrepriseId={
                                            entrepriseParentdeAgence.id
                                        }
                                    />
                                </>
                            )}

                            <Heading fontSize="2xl" mt={'20px'} mb={'10px'}>
                                Les vélos partagés de mon Agence
                            </Heading>
                            {!veloDeLAgenceLoaded && <VeloPanelLoading />}
                            {veloDeLAgenceLoaded &&
                                veloDeLAgence?.length === 0 && (
                                    <VeloPanelEmpty />
                                )}
                            {veloDeLAgenceLoaded &&
                                veloDeLAgence &&
                                veloDeLAgence?.length > 0 && (
                                    <>
                                        {veloDeLAgence?.map(function (
                                            equipment,
                                            idx
                                        ) {
                                            return (
                                                <VeloPanel
                                                    key={idx}
                                                    equipement={equipment}
                                                />
                                            )
                                        })}
                                    </>
                                )}

                            {canViewStat &&
                                allTargetAgenceUsers &&
                                allTargetAgenceUsers?.length > 0 && (
                                    <>
                                        <Heading
                                            fontSize="2xl"
                                            mt={'20px'}
                                            mb={'10px'}
                                        >
                                            Mes collaborateurs
                                        </Heading>
                                        <Accordion
                                            allowMultiple
                                            mt={'30px'}
                                            defaultIndex={allTargetAgenceUsers.map(
                                                (user, index) => {
                                                    return index
                                                }
                                            )}
                                        >
                                            {allTargetAgenceUsers?.map(
                                                function (user, idx) {
                                                    return (
                                                        <BlocTroisStatsAccordeon
                                                            key={idx}
                                                            utilisateurId={
                                                                user.id
                                                            }
                                                            titre={
                                                                user.nom +
                                                                ' ' +
                                                                user.prenom
                                                            }
                                                            defaultOpen={true}
                                                            compteur={
                                                                user.usageCetteAnnee
                                                            }
                                                            objectif={
                                                                entrepriseParentdeAgence?.objectif
                                                            }
                                                        />
                                                    )
                                                }
                                            )}
                                        </Accordion>
                                    </>
                                )}
                        </>
                    )}
                </Container>
            </ConditionalConnect>

            <ConditionalConnect showIfNotConnected>
                <Login />
            </ConditionalConnect>
        </>
    )
}
