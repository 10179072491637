import { Box, Text, SimpleGrid, Spinner, Center } from '@chakra-ui/react'
import { useState, useContext, useEffect } from 'react'
import { Moment } from 'moment'
import { GetMonthCalendrier } from '../../../firebaseManager/Communicator/CalendrierUsageCommunication'
import { CallFunctionEnregistrerCalendrier } from '../../../firebaseManager/Communicator/FunctionCallManager'
import { UtilisateurContext } from '../../../auth/UtilisateurContextProvider'

export type PropsBoxDay = {
    name: string
}
const BoxDay = ({ name }: PropsBoxDay) => {
    return (
        <Box p={'2px'} pt={'7px'} textAlign={'center'} as="b">
            {name}
        </Box>
    )
}

const BoxVide = () => {
    return <Box p={'2px'} pt={'7px'} textAlign={'center'}></Box>
}

export type PropBoxDaySelectionnable = {
    name: string
    index: number
    utilise: boolean
    updating: boolean
    modifiable: boolean
    action: (index: number) => void | undefined
}
const BoxDaySelectionnable = ({
    name,
    index,
    utilise,
    updating,
    modifiable,
    action,
}: PropBoxDaySelectionnable) => {
    return (
        <Box
            bgColor={updating ? 'blue.100' : utilise ? 'blue.300' : 'gray.200'}
            color={utilise ? 'white' : 'blue.800'}
            h={'40px'}
            w={'40px'}
            p={'2px'}
            pt={'7px'}
            textAlign={'center'}
            borderRadius={'10px'}
            onClick={(e) => {
                if (modifiable) {
                    action(index)
                }
            }}
        >
            {updating ? <Spinner /> : name}
        </Box>
    )
}

export type Props = {
    targetDay: Moment
    userId?: string
    modifiable: boolean
}

export const Calendrier = ({ targetDay, userId, modifiable }: Props) => {
    const utilisateur = useContext(UtilisateurContext)
    let targetUserId = userId ? userId : utilisateur?.id

    const numberday = targetDay.daysInMonth()
    const offset =
        targetDay.startOf('month').day() != 0
            ? targetDay.startOf('month').day() - 1
            : 6
    const offsetDay = new Array<Boolean>(offset).fill(true)

    const [usage, setUsage] = useState<boolean[]>([])
    const [usageUpdating, setUsageUpdating] = useState<boolean[]>([])
    const [usagePannelLoading, setUsagePannelLoading] = useState<boolean>(false)
    const [usagePannelLoaded, setUsagePannelLoaded] = useState<boolean>(false)
    const [compteur, setCompteur] = useState<number>(1) // permet de rerender la scene ( l'update du usestate usage ne rerender pas)

    useEffect(() => {
        if (utilisateur != null) {
            setUsagePannelLoading(true)
            GetMonthCalendrier(
                targetUserId!,
                targetDay.month(),
                targetDay.year()
            )
                .then((result) => {
                    if (result) {
                        setUsage(result.usage)
                    } else {
                        setUsage(new Array<boolean>(numberday).fill(false))
                    }
                    setUsageUpdating(new Array<boolean>(numberday).fill(false))
                    setUsagePannelLoading(false)
                    setUsagePannelLoaded(true)
                })
                .catch((e) => {
                    setUsage(new Array<boolean>(numberday).fill(false))
                    setUsagePannelLoading(false)
                    setUsagePannelLoaded(true)
                })
        }
    }, [utilisateur, targetDay])

    function setUsageHandler(index: number) {
        if (!modifiable) {
            return
        }
        // preparation d'un tableau cible temporaire pour envoyer pour enregistrement
        var tempUsage = usage
        tempUsage[index] = !tempUsage[index]
        setUsage(tempUsage)

        // Passage en loading de la case cible
        var tempUsageUpdating = usageUpdating
        tempUsageUpdating[index] = true
        setUsageUpdating(tempUsageUpdating)

        //REFRESH AFFICHAGE
        setCompteur(Date.now())

        // requete d'update
        CallFunctionEnregistrerCalendrier(
            targetUserId!,
            tempUsage,
            targetDay.month(),
            targetDay.year()
        )
            .then((result) => {
                // si result alors on est bon, pas de modification a réaliser
            })
            .catch((e) => {
                // si Erreur, on rollback la modification de data dans le tableau
                var tempUsage = usage
                tempUsage[index] = !tempUsage[index]
                setUsage(tempUsage)
            })
            .finally(() => {
                // Dans tout les cas on arrete l'etat loadting sur la case
                var tempUsageUpdating = usageUpdating
                tempUsageUpdating[index] = false
                setUsageUpdating(tempUsageUpdating)
                setCompteur(Date.now())
            })
    }

    let listNumber: string[] = []
    for (let i = 1; i < numberday + 1; i++) {
        listNumber.push(i + '')
    }
    return (
        <>
            <Center>
                <Text>
                    Selectionnez les trajets domicile-travail pour le mois de{' '}
                    <b>
                        {targetDay
                            .format('MMMM YYYY')
                            .replace(/^./, (str) => str.toUpperCase())}
                    </b>
                </Text>
            </Center>
            {usagePannelLoading && (
                <Box textAlign="center" py={10} px={6}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                    <Text color={'gray.500'} mt={3} mb={2}>
                        Chargement du calendrier ...
                    </Text>
                </Box>
            )}
            {usagePannelLoaded && !usagePannelLoading && (
                <SimpleGrid
                    columns={7}
                    spacing="3px"
                    w={'320px'}
                    ml={'calc((100% - 320px)/2) '}
                >
                    <BoxDay name="Lun" />
                    <BoxDay name="Mar" />
                    <BoxDay name="Mer" />
                    <BoxDay name="Jeu" />
                    <BoxDay name="Ven" />
                    <BoxDay name="Sam" />
                    <BoxDay name="Dim" />

                    {offsetDay.map((bool, i) => {
                        return <BoxVide key={i} />
                    })}
                    {listNumber.map((number, i) => {
                        return (
                            <BoxDaySelectionnable
                                key={i}
                                name={number}
                                index={i}
                                utilise={usage[i]}
                                updating={usageUpdating[i]}
                                modifiable={modifiable}
                                action={setUsageHandler}
                            />
                        )
                    })}
                </SimpleGrid>
            )}
        </>
    )
}
