/*
 * Barre de navigation générale du site
 */

import { Link as ReactRouterLink } from 'react-router-dom'
import { ConditionalConnect } from '../../auth/ConditionalConnect'
import { Box, Flex, Link as ChakraLink, Image, Stack } from '@chakra-ui/react'
import { ModalPDF } from '../ModalPDF'
import { ShowRoleFilter } from '../../auth/ShowRoleFilter'

interface PropsLink {
    url: string
    name: string
    onSuccess?: () => void
}

const NavButton = (props: PropsLink) => {
    return (
        <Box
            as={ReactRouterLink}
            to={props.url}
            px={3}
            py={2}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: '#31a1d7',
                color: 'white',
            }}
            backgroundColor={'#2d7bb6'}
            color={'white'}
        >
            {props.name}
        </Box>
    )
}

export default function NavBar() {
    return (
        <ConditionalConnect showIfConnected>
            <Box bg={'white'} px={4} borderBottom={'solid'}>
                <Flex
                    h={16}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <ChakraLink href="https://www.fenxmobility.com/">
                        <Box>
                            <Image h={'40px'} src={'logo.jpg'} />
                        </Box>
                    </ChakraLink>
                    <Flex alignItems={'center'}>
                        <Stack direction={'row'} spacing={7}>
                            <ShowRoleFilter forAdmin>
                                <NavButton
                                    url="/manage_equipements"
                                    name="Équipements"
                                />
                                <NavButton
                                    url="/manage_utilisateurs"
                                    name="Utilisateurs"
                                />
                                <NavButton
                                    url="/manage_enterprises"
                                    name="Entreprises"
                                />
                                <NavButton
                                    url="/administration"
                                    name="Administration"
                                />
                            </ShowRoleFilter>
                            <ShowRoleFilter forReparator>
                                <NavButton
                                    url="/vue_reparateur"
                                    name="Équipements"
                                />
                                <NavButton
                                    url="/mon_compte"
                                    name="Mon Compte"
                                />
                            </ShowRoleFilter>

                            <ShowRoleFilter forUser>
                                <ModalPDF
                                    button
                                    titre="Contrat d'entretien de votre entreprise"
                                />
                                <ConditionalConnect showIfUserCanAccessParc>
                                    <NavButton
                                        url="/mes_equipements_entreprise"
                                        name="Vision Parc"
                                    />
                                </ConditionalConnect>

                                <NavButton
                                    url="/mes_equipements"
                                    name="Mes Équipements"
                                />
                                <NavButton
                                    url="/mon_compte"
                                    name="Mon Compte"
                                />
                            </ShowRoleFilter>

                            <ConditionalConnect showIfNotConnected>
                                <NavButton url="/login" name="Connexion" />
                            </ConditionalConnect>
                        </Stack>
                    </Flex>
                </Flex>
            </Box>
        </ConditionalConnect>
    )
}
