/*
 * Pied de page général du site
 */
import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spacer,
    Flex,
    useDisclosure,
} from '@chakra-ui/react'
import { useState, useContext } from 'react'
import moment, { Moment } from 'moment'
import { UtilisateurContext } from '../../../auth/UtilisateurContextProvider'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Button as ButtonCustom } from '../../Button'
import { Calendrier } from '../Calendrier'

export type PropsModalCalendrier = {
    userId?: string
    titre?: string
    modifiable?: boolean
}

export const ModalCalendrier = ({
    userId,
    titre,
    modifiable = false,
}: PropsModalCalendrier) => {
    const currentUser = useContext(UtilisateurContext)
    let targetUserId = userId ? userId : currentUser?.id

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [targetMonth, setTargetMonth] = useState<Moment>(moment())

    const lastmonth = targetMonth.clone().subtract(1, 'months')
    const nextmonth = targetMonth.clone().add(1, 'months')
    return (
        <>
            <ButtonCustom onClick={onOpen}>
                {titre ? titre : "Voir l'historique"}
            </ButtonCustom>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Usage :{' '}
                        {targetMonth
                            .format('MMMM YYYY')
                            .replace(/^./, (str) => str.toUpperCase())}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box h={'360px'}>
                            <Calendrier
                                targetDay={targetMonth}
                                userId={targetUserId!}
                                modifiable={modifiable}
                            />
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Flex w={'100%'}>
                            <Button
                                onClick={(e) => {
                                    setTargetMonth(lastmonth)
                                }}
                            >
                                <ChevronLeftIcon fontSize={'2xl'} />
                                {lastmonth.format('MMMM YYYY')}
                            </Button>
                            <Spacer />

                            <Button
                                hidden={nextmonth > moment()}
                                onClick={(e) => {
                                    setTargetMonth(nextmonth)
                                }}
                            >
                                {nextmonth.format('MMMM YYYY')}
                                <ChevronRightIcon fontSize={'2xl'} />
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
