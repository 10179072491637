/**
 *  écran pré fait contenant les information des 3 statitisquse primordial d'une agence ou d'une entreprise (+ accordéon avec les infos de ses agences)
 *  Nombre de vélo | nombre de CO2 économisé | nombre de kilométre parcouru
 */

import {
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Wrap,
    Image,
    Text,
    Box,
    Center,
    Spinner,
    IconButton,
    Progress,
    extendTheme,
    ChakraProvider,
} from '@chakra-ui/react'
import { CustomBox } from '../../components/CustomBox'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import {
    StatOnEquipement,
    StatGlobalOnEquipement,
    StatVisionParc,
} from '../../firebaseManager/Communicator/EquipementCommunicator'
import {
    CompteurObjectifPourcentage,
    KlmToCO2AutoText,
} from '../../utils/helpers'
import { UtilisateurContext } from '../../auth/UtilisateurContextProvider'
import { ModalCalendrierAggregatedViewer } from '../CalendrierUsage/ModalCalendrierAggregateViewer'
import { ConditionalConnect } from '../../auth/ConditionalConnect'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

export type Props = {
    agenceId?: string
    entrepriseId?: string
    utilisateurId?: string
    titre?: string
    defaultOpen?: boolean
    compteur?: number
    objectif?: number
    globalValue?: boolean
}

export const BlocTroisStatsAccordeon = ({
    agenceId,
    entrepriseId,
    utilisateurId,
    titre,
    defaultOpen = false,
    compteur,
    objectif,
}: Props) => {
    const [openedAccordeon, setOpenedAccordeon] = useState<boolean>(defaultOpen)
    const navigate = useNavigate()
    const targetEntreprise = entrepriseId && !agenceId ? true : false
    const targetAgence = agenceId && entrepriseId ? true : false
    const targetUtilisateur =
        utilisateurId && !entrepriseId && !agenceId ? true : false

    const pourcentageObjectif = Math.min(
        CompteurObjectifPourcentage(compteur, objectif),
        100
    )
    const pourcentageObjectifColorRed = 255 - pourcentageObjectif * 1.5
    const pourcentageObjectifColorGreen = 40 + pourcentageObjectif * 1.5

    return (
        <AccordionItem>
            <AccordionButton
                onClick={() => {
                    setOpenedAccordeon(true)
                }}
            >
                <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight={'bold'} fontSize={'xl'}>
                        <Box float="left">{titre}</Box>{' '}
                        {targetUtilisateur && (
                            <>
                                <Progress
                                    float="right"
                                    mt={'5px'}
                                    ml="20px"
                                    mr="20px"
                                    w={'120px'}
                                    backgroundColor={'white'}
                                    h={'22px'}
                                    border={'solid gray'}
                                    borderRadius={'5px'}
                                    hasStripe
                                    isAnimated
                                    colorScheme={
                                        pourcentageObjectif === 100
                                            ? 'green'
                                            : 'red'
                                    }
                                    value={pourcentageObjectif}
                                />
                                {/* <Text
                                    float="right"
                                    mt={'5px'}
                                    ml="20px"
                                    fontSize={'15px'}
                                >
                                    Trajets : {compteur}/{objectif}
                                </Text>*/}

                                <Text
                                    float="right"
                                    mt={'00px'}
                                    ml="20px"
                                    fontSize={'20px'}
                                    as="b"
                                    color={
                                        pourcentageObjectif === 100
                                            ? '#108f0b'
                                            : '#b80413'
                                    }
                                >
                                    {pourcentageObjectif} %
                                </Text>
                            </>
                        )}
                        <ConditionalConnect showIfAdmin>
                            <IconButton
                                float="left"
                                aria-label="Accèder"
                                bgColor={'white'}
                                ml={'10px'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (targetEntreprise) {
                                        navigate(
                                            '/edit_enterprise?id_entreprise=' +
                                                entrepriseId
                                        )
                                    } else if (targetAgence) {
                                        navigate(
                                            '/edit_agence?id_entreprise=' +
                                                entrepriseId +
                                                '&id_agence=' +
                                                agenceId
                                        )
                                    } else if (targetUtilisateur) {
                                        navigate(
                                            '/edit_utilisateur?id_utilisateur=' +
                                                utilisateurId
                                        )
                                    }
                                }}
                            >
                                <ExternalLinkIcon></ExternalLinkIcon>
                            </IconButton>
                        </ConditionalConnect>
                    </Text>
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={'10px'} pt={'10px'}>
                {targetEntreprise && openedAccordeon && (
                    <BlocTroisStats entrepriseId={entrepriseId} />
                )}
                {targetAgence && openedAccordeon && (
                    <BlocTroisStats
                        agenceId={agenceId}
                        entrepriseId={entrepriseId}
                    />
                )}
                {targetUtilisateur && openedAccordeon && (
                    <BlocTroisStats utilisateurId={utilisateurId} />
                )}
            </AccordionPanel>
        </AccordionItem>
    )
}

export const BlocTroisStats = ({
    agenceId,
    entrepriseId,
    utilisateurId,
    globalValue = false,
}: Props) => {
    const authentification = useContext(AuthContext)
    const actualUser = useContext(UtilisateurContext)

    const [statVisionParc, setStatVisionParc] =
        useState<StatVisionParc | null>()
    const [statVisionParcLoaded, setStatVisionParcLoaded] =
        useState<boolean>(false)

    function refreshStatEntreprise(id_entreprise: string) {
        StatOnEquipement(id_entreprise, undefined, undefined)
            .then((result) => {
                setStatVisionParc(result)
                setStatVisionParcLoaded(true)
            })
            .catch((e) => {
                setStatVisionParcLoaded(true)
                setStatVisionParc(null)
            })
    }

    function refreshStatAgence(id_agence: string, entrepriseId: string) {
        StatOnEquipement(entrepriseId, id_agence, undefined)
            .then((result) => {
                setStatVisionParc(result)
                setStatVisionParcLoaded(true)
            })
            .catch((e) => {
                setStatVisionParc(null)
                setStatVisionParcLoaded(true)
            })
    }

    function refreshStatUser(id_agence: string, id_utilisateur: string) {
        StatOnEquipement(undefined, id_agence, id_utilisateur)
            .then((result) => {
                setStatVisionParc(result)
                setStatVisionParcLoaded(true)
            })
            .catch((e) => {
                setStatVisionParc(null)
                setStatVisionParcLoaded(true)
            })
    }

    function refreshStatGlobal() {
        StatGlobalOnEquipement()
            .then((result) => {
                setStatVisionParc(result)
                setStatVisionParcLoaded(true)
            })
            .catch((e) => {
                setStatVisionParc(null)
                setStatVisionParcLoaded(true)
            })
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            if (entrepriseId && !agenceId) {
                refreshStatEntreprise(entrepriseId)
            } else if (entrepriseId && agenceId) {
                refreshStatAgence(agenceId, entrepriseId)
            } else if (utilisateurId) {
                refreshStatUser(actualUser?.agenceAppartenance!, utilisateurId)
            } else if (globalValue) {
                refreshStatGlobal()
            }
        }
    }, [authentification])

    return (
        <>
            <Wrap>
                <StatPanel
                    image={'/icon/bike.svg'}
                    title={
                        statVisionParc?.countOfBike +
                        ' Vélo' +
                        (statVisionParc && statVisionParc.countOfBike > 1
                            ? 's'
                            : '')
                    }
                    subtitle={'au total'}
                    bg="#2d7bb6"
                    color="white"
                    isLoading={!statVisionParcLoaded}
                    isEmpty={
                        statVisionParcLoaded && !statVisionParc?.countOfBike
                    }
                />
                <StatPanel
                    image={'/icon/leaf2.svg'}
                    bg="white"
                    color="green"
                    title={KlmToCO2AutoText(statVisionParc?.totalKilometrage)}
                    subtitle={'CO2 économisé'}
                    isLoading={!statVisionParcLoaded}
                    isEmpty={
                        statVisionParcLoaded &&
                        !statVisionParc?.totalKilometrage
                    }
                />

                <StatPanel
                    image={'/icon/path.svg'}
                    bg="#27292c"
                    color="white"
                    title={statVisionParc?.totalKilometrage + ' Kms'}
                    subtitle={'Distance totale '}
                    isLoading={!statVisionParcLoaded}
                    isEmpty={
                        statVisionParcLoaded &&
                        !statVisionParc?.totalKilometrage
                    }
                />
            </Wrap>
            {statVisionParc?.averageKilometrage && (
                <Center mt={'15px'}>
                    <Text>
                        Chaque vélo parcours en moyenne{' '}
                        <b>{statVisionParc?.averageKilometrage.toFixed(2)}</b>{' '}
                        kms
                    </Text>
                </Center>
            )}
            {(agenceId !== undefined || entrepriseId !== undefined) && (
                <Center mt={'15px'}>
                    <ModalCalendrierAggregatedViewer
                        entrepriseId={entrepriseId}
                        agenceId={agenceId}
                    />
                </Center>
            )}
        </>
    )
}

export type StatProps = {
    image: string
    title: string
    subtitle: string
    color?: string
    isLoading?: boolean
    isEmpty?: boolean
    bg?: string
}

const StatPanel = (props: StatProps) => {
    return (
        <CustomBox
            w={{
                base: '100%',
                sm: 'calc(50% - 5px)',
                md: 'calc(33% - 5px)',
            }}
            h={'100px'}
            borderRadius={'15px'}
            padding={'15px'}
            paddingLeft={'25px'}
            backgroundColor={props.bg ? props.bg : 'white'}
            color={props.color ? props.color : 'black'}
        >
            <Image src={props.image} float={'right'} h={'50px'} mt={'8px'} />
            {props.isLoading && (
                <>
                    <Center mt={'10px'}>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="white"
                            color="black"
                            size="xl"
                        />
                    </Center>
                </>
            )}
            {!props.isLoading && (
                <>
                    <Box mt={'8px'}>
                        <Center>
                            <Text fontSize="xl" as="b">
                                {props.title}
                            </Text>
                        </Center>
                    </Box>
                    <Box mt={'5px'}>
                        <Center>
                            <Text fontSize="sm">{props.subtitle}</Text>
                        </Center>
                    </Box>
                </>
            )}
        </CustomBox>
    )
}
