/*
 * Page dédiée a l'édition d'une entreprise en particulier
 */
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useDisclosure,
    ModalBody,
    Spinner,
    Text,
    Container,
    ModalCloseButton,
    Box,
    Center,
    Flex,
    Spacer,
    Heading,
    Wrap,
} from '@chakra-ui/react'
import { Agence, Entreprise } from '../../model'
import { GetAllAgencesForTargetEntreprise } from '../../firebaseManager/Communicator/AgenceCommunicator'
import { useSearchParams } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'
import { CloseIcon, EditIcon } from '@chakra-ui/icons'
import { Button } from '../../components/Button'
import { ButtonToolTip } from '../../components/ButtonToolTip'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { AddIcon, ViewIcon, PhoneIcon, EmailIcon } from '@chakra-ui/icons'
import { GetTargetEntreprise } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { BreadCrumbBuilder } from '../../components/BreadCrumbBuilder'
import { CustomBox } from '../../components/CustomBox'
import { CreationEditionEntreprise } from '../../components/Formulaire/CreationEditionEntreprise'
import { ConditionalConnectAdminComplex } from '../../auth/ConditionalConnectAdminComplex'
import { CreationEditionAgence } from '../../components/Formulaire/CreationEditionAgence'
import { ModalSuppressionAgence } from '../../components/ModalSuppression'
import { ModalPDF } from '../../components/ModalPDF'
import { ExportActionButton } from '../../components/ExportActionButton'

export const ManageEditEntreprise = () => {
    const authentification = useContext(AuthContext)

    const [targetEntreprise, setTargetEntreprise] =
        useState<Entreprise | null>()
    const [allTargetEntrepriseAgences, setAllTargetEntrepriseAgences] =
        useState<Agence[] | null>()

    const [searchParams] = useSearchParams()
    const id_entreprise: string = searchParams.get('id_entreprise')!

    const {
        isOpen: isOpenEditEntreprise,
        onOpen: onOpenEditEntreprise,
        onClose: onCloseEditEntreprise,
    } = useDisclosure()

    const {
        isOpen: isOpenModalAddAgence,
        onOpen: onOpenModalAddAgence,
        onClose: onCloseModalAddAgence,
    } = useDisclosure()

    function refreshTagetEntreprise() {
        GetTargetEntreprise(id_entreprise!)
            .then((result) => {
                setTargetEntreprise(result)
            })
            .catch((e) => setTargetEntreprise(null))
    }

    function refreshAllTargetEntrepriseAgences() {
        GetAllAgencesForTargetEntreprise(id_entreprise!)
            .then((result) => {
                setAllTargetEntrepriseAgences(result)
            })
            .catch((e) => setAllTargetEntrepriseAgences(null))
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            console.log('Target Entreprise : ' + id_entreprise)
            if (id_entreprise != null) {
                refreshTagetEntreprise()
                refreshAllTargetEntrepriseAgences()
            }
        }
    }, [authentification])

    return (
        <ConditionalConnectAdminComplex>
            <BreadCrumbBuilder
                items={[
                    {
                        name: 'Entreprises',
                        url: '/manage_enterprises',
                    },
                    {
                        name: targetEntreprise?.nom!,
                        url: '',
                    },
                ]}
            />

            <Container maxW="container.lg">
                {targetEntreprise ? (
                    <>
                        <Center>
                            <Heading>{targetEntreprise?.nom}</Heading>
                        </Center>
                        {!isOpenEditEntreprise && (
                            <CustomBox mt={'30px'}>
                                <EditIcon
                                    boxSize={6}
                                    float={'right'}
                                    onClick={onOpenEditEntreprise}
                                />

                                <Text ml={'10px'} as="b">
                                    Information de l'entreprise{' '}
                                </Text>
                                <Box m={'30px'}>
                                    <Text>
                                        <b>Nom</b> : {targetEntreprise?.nom}
                                    </Text>
                                    <Text>
                                        <b>Email</b> :{' '}
                                        {targetEntreprise?.mailContact}
                                    </Text>
                                    <Text>
                                        <b>Téléphone</b> :{' '}
                                        {targetEntreprise?.telContact}
                                    </Text>
                                    <Text>
                                        <b>Adresse</b> :{' '}
                                        {targetEntreprise?.adresse}
                                    </Text>
                                    <Text>
                                        <b>Interlocuteur</b> :{' '}
                                        {targetEntreprise?.interlocuteur}
                                    </Text>
                                    <Text>
                                        <b>Type du contrat</b> :{' '}
                                        {targetEntreprise?.typeContrat}
                                    </Text>
                                    <Text>
                                        <b>Numéro de contrat</b> :{' '}
                                        {targetEntreprise?.noContrat}
                                    </Text>

                                    <Text mt="10px">
                                        <b>Téléphone d'assurance</b> :{' '}
                                        {targetEntreprise?.telAssurance}
                                    </Text>
                                    {/*<Text>
                                        <b>Mail d'assurance</b> :{' '}
                                        {targetEntreprise?.mailAssurance}
                                    </Text>*/}
                                    <Text>
                                        <b>Type formule d'assurance</b> :{' '}
                                        {targetEntreprise?.formuleAssurance}
                                    </Text>
                                    <Text>
                                        <b>URL Contrat d'assurance</b> :{' '}
                                        {targetEntreprise?.contratAssurance && (
                                            <ModalPDF
                                                url={
                                                    targetEntreprise?.contratAssurance
                                                }
                                                lien
                                                titre="Contrat d'assurance de votre entreprise"
                                            />
                                        )}
                                        {!targetEntreprise?.contratAssurance && (
                                            <>Non renseigné</>
                                        )}
                                    </Text>

                                    <Text>
                                        <b>URL Contrat d'entretien</b> :{' '}
                                        {targetEntreprise?.contratEntretien && (
                                            <ModalPDF
                                                url={
                                                    targetEntreprise?.contratEntretien
                                                }
                                                lien
                                                titre="Contrat d'entretien de votre entreprise"
                                            />
                                        )}
                                        {!targetEntreprise?.contratEntretien && (
                                            <>Non renseigné</>
                                        )}
                                    </Text>
                                    <Text mt="10px">
                                        <b>
                                            Objectif d'usage des utilisateurs de
                                            vélo
                                        </b>{' '}
                                        : {targetEntreprise?.objectif}{' '}
                                        utilisations sur l'année
                                    </Text>
                                    <Text mt={'20px'}>
                                        <b>Nombre d'agences</b> :{' '}
                                        {allTargetEntrepriseAgences
                                            ? allTargetEntrepriseAgences?.length
                                            : 0}
                                    </Text>
                                    <Wrap mt={'20px'}>
                                        <Spacer />

                                        <Box>
                                            <Button
                                                size={'sm'}
                                                p="10px"
                                                colorScheme="gray-dark"
                                                as={ReactRouterLink}
                                                to={
                                                    '/mes_equipements_entreprise?id_entreprise=' +
                                                    id_entreprise
                                                }
                                            >
                                                Vision parc entreprise
                                            </Button>
                                        </Box>

                                        <ExportActionButton
                                            exportVelo
                                            entreprise={targetEntreprise}
                                        />
                                        <Spacer />
                                    </Wrap>
                                </Box>
                            </CustomBox>
                        )}
                        {isOpenEditEntreprise && (
                            <CustomBox>
                                <CloseIcon
                                    color="red"
                                    boxSize={6}
                                    float={'right'}
                                    onClick={onCloseEditEntreprise}
                                />

                                <Text ml={'10px'} as="b">
                                    Édition de l'entreprise
                                </Text>
                                <Box m={'30px'}>
                                    <CreationEditionEntreprise
                                        entreprise={targetEntreprise!}
                                        entrepriseId={id_entreprise}
                                        onSucess={() => {
                                            onCloseEditEntreprise()
                                            refreshTagetEntreprise()
                                        }}
                                    />
                                </Box>
                            </CustomBox>
                        )}
                    </>
                ) : (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center> Chargement de l'entreprise</Center>
                    </CustomBox>
                )}

                <Button
                    onClick={onOpenModalAddAgence}
                    p={4}
                    mt={'30px'}
                    mb={'30px'}
                >
                    <AddIcon /> Créer une agence
                </Button>

                {allTargetEntrepriseAgences == null && (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center>Chargement des agences</Center>
                    </CustomBox>
                )}
                {allTargetEntrepriseAgences &&
                    allTargetEntrepriseAgences?.length === 0 && (
                        <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                            <Center>
                                L'entreprise n'a pas encore d'agence
                            </Center>
                        </CustomBox>
                    )}
                {allTargetEntrepriseAgences &&
                    allTargetEntrepriseAgences?.length > 0 && (
                        <>
                            {allTargetEntrepriseAgences?.map(function (
                                agence,
                                idx
                            ) {
                                return (
                                    <CustomBox
                                        pl={'25px'}
                                        pr={'25px'}
                                        pt={'15px'}
                                        pb={'15px'}
                                        h={'75px'}
                                        ml={'50px'}
                                        mr={'50px'}
                                        mb={'10px'}
                                        key={idx}
                                    >
                                        <Flex>
                                            <Box mt={'10px'}>
                                                <Text as="b">{agence.nom}</Text>
                                            </Box>
                                            <Spacer />

                                            <Box>
                                                <ButtonToolTip
                                                    label={agence.mailContact!}
                                                    textToCopy={
                                                        agence.mailContact!
                                                    }
                                                >
                                                    <EmailIcon />
                                                </ButtonToolTip>
                                            </Box>
                                            <Box>
                                                <ButtonToolTip
                                                    label={agence.telContact!}
                                                    textToCopy={
                                                        agence.telContact!
                                                    }
                                                >
                                                    <PhoneIcon />
                                                </ButtonToolTip>
                                            </Box>
                                            <Button
                                                colorScheme="blue"
                                                p={0}
                                                as={ReactRouterLink}
                                                to={
                                                    '/edit_agence?id_entreprise=' +
                                                    id_entreprise +
                                                    '&id_agence=' +
                                                    agence.id
                                                }
                                            >
                                                <ViewIcon />
                                            </Button>
                                            <ModalSuppressionAgence
                                                targetId={agence.id}
                                                nomEntite={agence.nom}
                                                onSuccess={() =>
                                                    refreshAllTargetEntrepriseAgences()
                                                }
                                            />
                                        </Flex>
                                    </CustomBox>
                                )
                            })}
                        </>
                    )}
            </Container>

            <Modal
                isOpen={isOpenModalAddAgence}
                onClose={onCloseModalAddAgence}
                size={'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Créer une agence</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <CreationEditionAgence
                            entrepriseId={id_entreprise}
                            onSucess={() => {
                                onCloseModalAddAgence()
                                refreshAllTargetEntrepriseAgences()
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </ConditionalConnectAdminComplex>
    )
}
