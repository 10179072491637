/*
 * Class d'intération avec firestore pour les éléments : Equipement
 */
import { CategorieEquipement, Equipement } from '../../../model'
import { db } from '../..'
import {
    collection,
    query,
    doc,
    getDocs,
    deleteDoc,
    where,
    and,
    documentId,
    limit,
    QueryDocumentSnapshot,
    DocumentData,
    startAfter,
    updateDoc,
    getAggregateFromServer,
    count,
    sum,
    average,
} from 'firebase/firestore'

export async function GetMesEquipements(
    utilisateurId: string
): Promise<Equipement[]> {
    const q = query(
        collection(db, 'equipements'),
        where('utilisateurAppartenance', '==', utilisateurId)
    )
    const querySnapshot = await getDocs(q)
    const mesEquipements: Equipement[] = querySnapshot.docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data(),
        } as Equipement
    })
    return mesEquipements
}

export async function GetTargetEquipement(
    equipementId: string
): Promise<Equipement | undefined> {
    const q = query(
        collection(db, 'equipements'),
        where(documentId(), '==', equipementId),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as Equipement
    }
    return undefined
}

export async function GetTargetEquipementWithNumeroMarquage(
    equipementNumeroMarquage: string
): Promise<Equipement | undefined> {
    const q = query(
        collection(db, 'equipements'),
        where('numeroMarquage', '==', equipementNumeroMarquage),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as Equipement
    }
    return undefined
}

export async function GetAllEquipements(): Promise<Equipement[]> {
    const q = query(collection(db, 'equipements'))
    const querySnapshot = await getDocs(q)
    const allAgences: Equipement[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Equipement
    })
    return allAgences
}

export async function GetAllEquipementsForUser(
    utilisateurId: string
): Promise<Equipement[]> {
    const q = query(
        collection(db, 'equipements'),
        where('utilisateurAppartenance', '==', utilisateurId)
    )
    const querySnapshot = await getDocs(q)
    const allAgences: Equipement[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Equipement
    })
    return allAgences
}

export async function GetAllSharedEquipementsForAgence(
    agenceId: string
): Promise<Equipement[]> {
    const q = query(
        collection(db, 'equipements'),
        and(
            where('agenceAppartenance', '==', agenceId),
            where('utilisateurAppartenance', '==', '')
        )
    )
    const querySnapshot = await getDocs(q)
    const allAgences: Equipement[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Equipement
    })
    return allAgences
}

export async function GetAllFilteredEquipements(
    categorie: string,
    marque: string,
    modele: string,
    numeroSerie: string,
    numeroMarquage: string,
    entreprise: string,
    agence: string,
    partager: boolean,
    hivernage: boolean,
    last: QueryDocumentSnapshot<DocumentData, DocumentData> | null
): Promise<{
    list: Equipement[]
    last: QueryDocumentSnapshot<DocumentData, DocumentData>
}> {
    let q = query(collection(db, 'equipements'), limit(15))
    if (categorie) {
        q = query(q, where('categorie', '==', categorie))
    }
    if (marque) {
        q = query(
            q,
            where('marque', '>=', marque),
            where('marque', '<=', marque + '\uf8ff')
        )
    }
    if (modele) {
        q = query(
            q,
            where('modele', '>=', modele),
            where('modele', '<=', modele + '\uf8ff')
        )
    }
    if (numeroSerie) {
        q = query(
            q,
            where('numeroSerie', '>=', numeroSerie),
            where('numeroSerie', '<=', numeroSerie + '\uf8ff')
        )
    }
    if (numeroMarquage) {
        q = query(
            q,
            where('numeroMarquage', '>=', numeroMarquage),
            where('numeroMarquage', '<=', numeroMarquage + '\uf8ff')
        )
    }
    if (entreprise) {
        q = query(q, where('entrepriseAppartenance', '==', entreprise))

        if (agence) {
            q = query(q, where('agenceAppartenance', '==', agence))
        }
    }
    if (partager) {
        q = query(q, where('utilisateurAppartenance', '==', ''))
    }
    if (hivernage) {
        q = query(q, where('hivernage', '==', true))
    }
    if (last) {
        q = query(q, startAfter(last))
    }
    const querySnapshot = await getDocs(q)
    const allEquipements: Equipement[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Equipement
    })
    return {
        list: allEquipements,
        last: querySnapshot.docs[querySnapshot.docs.length - 1],
    }
}

export async function UpdateKilometrageOnTargetEquipement(
    equipementId: string,
    kilometrage: number
): Promise<void> {
    await updateDoc(doc(db, 'equipements/' + equipementId), {
        kilometrage: kilometrage,
        dateKilometrage: Date.now(),
    })
}

export type StatVisionParc = {
    countOfBike: number
    totalKilometrage: number | null
    averageKilometrage: number | null
}

export async function StatOnEquipement(
    enterpriseID?: string,
    agenceID?: string,
    UtilisateurID?: string
): Promise<StatVisionParc> {
    const coll = collection(db, 'equipements')
    let q = query(coll, where('categorie', '==', CategorieEquipement.Velo))

    if (enterpriseID) {
        q = query(q, where('entrepriseAppartenance', '==', enterpriseID))
    }
    if (agenceID) {
        q = query(q, where('agenceAppartenance', '==', agenceID))
    }
    if (UtilisateurID) {
        q = query(q, where('utilisateurAppartenance', '==', UtilisateurID))
    }
    const snapshot = await getAggregateFromServer(q, {
        countOfDocs: count(),
        totalKilometrage: sum('kilometrage'),
        averageKilometrage: average('kilometrage'),
    })

    return {
        countOfBike: snapshot.data().countOfDocs,
        totalKilometrage: snapshot.data().totalKilometrage,
        averageKilometrage: snapshot.data().averageKilometrage,
    }
}

export async function StatGlobalOnEquipement(): Promise<StatVisionParc> {
    const coll = collection(db, 'equipements')
    let q = query(coll, where('categorie', '==', CategorieEquipement.Velo))
    q = query(q, where('entrepriseAppartenance', '!=', 'qxII5c879iCqmoIMeG2x'))
    const snapshot = await getAggregateFromServer(q, {
        countOfDocs: count(),
        totalKilometrage: sum('kilometrage'),
        averageKilometrage: average('kilometrage'),
    })

    return {
        countOfBike: snapshot.data().countOfDocs,
        totalKilometrage: snapshot.data().totalKilometrage,
        averageKilometrage: snapshot.data().averageKilometrage,
    }
}

export async function DeleteOneEquipement(equipementId: string): Promise<void> {
    await deleteDoc(doc(db, 'equipements', equipementId))
}
