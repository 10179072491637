/**
 * Context provider pour fournir les informations de l'agence de l'utilisateur connecté
 */

import React, { ReactNode, useState, useEffect, useContext } from 'react'
import { GetTargetAgence } from '../firebaseManager/Communicator/AgenceCommunicator'
import { Agence } from '../model'
import { UtilisateurContext } from './UtilisateurContextProvider'

export const AgenceContext = React.createContext<Agence | undefined>(undefined)

interface Props {
    children: ReactNode
}

export const AgenceContextProvider = (props: Props) => {
    const utilisateur = useContext(UtilisateurContext)
    const [agence, setAgence] = useState<Agence | undefined>()

    useEffect(() => {
        console.log()
        if (utilisateur?.agenceAppartenance != null) {
            GetTargetAgence(utilisateur.agenceAppartenance)
                .then((result) => {
                    //console.log('OK Agence : ')
                    //console.log(result)
                    setAgence(result)
                })
                .catch((e) => {
                    console.log('ERR Agence ' + e)
                    setAgence(undefined)
                })
        }
    }, [utilisateur])

    return (
        <AgenceContext.Provider value={agence}>
            {props.children}
        </AgenceContext.Provider>
    )
}
