/*
 * Formulaire autonome et réutilisable permettant de créer ou d'éditer un objet Entreprise
 */

import { db } from '../../../firebaseManager'
import {
    FormControl,
    Select,
    Input,
    InputGroup,
    InputLeftAddon,
    useToast,
    Center,
    Text,
} from '@chakra-ui/react'

import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'

import { Button } from '../../Button'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Entreprise, TypeContrat } from '../../../model'
import ReactTextareaAutosize from 'react-textarea-autosize'

const validationSchema = yup.object().shape({
    nom: yup.string().required(),
    mailContact: yup.string(),
    telContact: yup.string(),
    adresse: yup.string(),
    interlocuteur: yup.string(),
    noContrat: yup.string(),
    typeContrat: yup.string().required(),
    telAssurance: yup.string(),
    mailAssurance: yup.string(),
    formuleAssurance: yup.string(),
    contratAssurance: yup.string(),
    contratEntretien: yup.string(),
    objectif: yup.number(),
})

export type AddEntrepriseValue = {
    nom: string
    mailContact: string
    telContact: string
    adresse: string
    interlocuteur: string
    noContrat: string
    typeContrat: string
    telAssurance: string
    mailAssurance: string
    formuleAssurance: string
    contratAssurance: string
    contratEntretien: string
    objectif: number
}

export type Props = {
    entreprise?: Entreprise
    entrepriseId?: string
    onSucess?: () => void
}

export const CreationEditionEntreprise = (props: Props) => {
    const editMode =
        props.entreprise != null &&
        props.entreprise !== undefined &&
        props.entrepriseId != null
    const toast = useToast()
    const formikAddEntreprise = useFormik({
        validationSchema,
        initialValues: {
            nom: editMode && props.entreprise!.nom ? props.entreprise!.nom : '',
            mailContact:
                editMode && props.entreprise!.mailContact
                    ? props.entreprise!.mailContact
                    : '',
            telContact:
                editMode && props.entreprise!.telContact
                    ? props.entreprise!.telContact
                    : '',
            adresse:
                editMode && props.entreprise!.adresse
                    ? props.entreprise!.adresse
                    : '',
            interlocuteur:
                editMode && props.entreprise!.interlocuteur
                    ? props.entreprise!.interlocuteur
                    : '',
            noContrat:
                editMode && props.entreprise!.noContrat
                    ? props.entreprise!.noContrat
                    : '',
            typeContrat:
                editMode && props.entreprise!.typeContrat
                    ? props.entreprise!.typeContrat
                    : '',
            telAssurance:
                editMode && props.entreprise!.telAssurance
                    ? props.entreprise!.telAssurance
                    : '',
            mailAssurance:
                editMode && props.entreprise!.mailAssurance
                    ? props.entreprise!.mailAssurance
                    : '',
            formuleAssurance:
                editMode && props.entreprise!.formuleAssurance
                    ? props.entreprise!.formuleAssurance
                    : '',
            contratAssurance:
                editMode && props.entreprise!.contratAssurance
                    ? props.entreprise!.contratAssurance
                    : '',
            contratEntretien:
                editMode && props.entreprise!.contratEntretien
                    ? props.entreprise!.contratEntretien
                    : '',
            objectif:
                editMode && props.entreprise!.objectif
                    ? props.entreprise!.objectif
                    : 0,
        },
        onSubmit: (values: AddEntrepriseValue, actions) => {
            if (editMode) {
                updateDoc(doc(db, 'entreprises/' + props.entrepriseId), {
                    ...values,
                })
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Entreprise mise a jour!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on edit ' + e)
                        actions.setSubmitting(false)
                    })
            } else {
                setDoc(doc(collection(db, 'entreprises')), values)
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Entreprise créé!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on add' + e)
                        actions.setSubmitting(false)
                    })
            }
        },
    })

    return (
        <FormikProvider value={formikAddEntreprise}>
            <FormControl id="nom" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Nom
                        <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        name="nom"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.nom}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="mailContact" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Mail de contact" />
                    <Input
                        name="mailContact"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.mailContact}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="telContact" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Téléphone de contact" />{' '}
                    <Input
                        name="telContact"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.telContact}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="adresse" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Adresse" />{' '}
                    <Input
                        name="adresse"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.adresse}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="interlocuteur" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Interlocuteur" />{' '}
                    <Input
                        name="interlocuteur"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.interlocuteur}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="noContrat" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Numéro de Contrat" />{' '}
                    <Input
                        name="noContrat"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.noContrat}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="typeContrat" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Type de contrat
                        <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Select
                        placeholder="Selectionner un type de contrat"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.typeContrat}
                    >
                        {Object.keys(TypeContrat).map(function (cat, idx) {
                            return (
                                <option key={idx} value={cat.toString()}>
                                    {cat.toString()}
                                </option>
                            )
                        })}
                    </Select>
                </InputGroup>
            </FormControl>

            <FormControl id="telAssurance" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Téléphone d'assurance" />
                    <Input
                        name="telAssurance"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.telAssurance}
                    />
                </InputGroup>
            </FormControl>
            {/**
            * 
            * <FormControl id="mailAssurance" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Mail d'assurance" />
                    <Input
                        name="mailAssurance"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.mailAssurance}
                    />
                </InputGroup>
            </FormControl>
            */}
            <FormControl id="formuleAssurance" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Type formule d'assurance" />
                    <Input
                        name="formuleAssurance"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.formuleAssurance}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="contratAssurance" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="URL Contrat d'assurance" />
                    <Input
                        name="contratAssurance"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.contratAssurance}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="contratEntretien" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="URL Contrat d'entretien" />
                    <Input
                        name="contratEntretien"
                        type="text"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.contratEntretien}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="objectif" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Objectif d'utilisation par ans" />{' '}
                    <Input
                        name="objectif"
                        type="number"
                        onChange={formikAddEntreprise.handleChange}
                        value={formikAddEntreprise.values.objectif}
                    />
                </InputGroup>
            </FormControl>
            <Center>
                <Button
                    mt={4}
                    mr={3}
                    onClick={formikAddEntreprise.submitForm}
                    isDisabled={
                        !formikAddEntreprise.isValid ||
                        !formikAddEntreprise.dirty
                    }
                    isLoading={formikAddEntreprise.isSubmitting}
                >
                    {editMode ? <>Editer</> : <>Ajouter</>}
                </Button>
            </Center>
        </FormikProvider>
    )
}
