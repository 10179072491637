/*
 * Graphique de l'historique de kilometrage vélo
 * Récupére les (10) lignes de journal de déclaration kilométrique de l'équipement pour construire un graphique
 */

import { Box, Center, Spinner, Text } from '@chakra-ui/react'
import {
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    BarChart,
} from 'recharts'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import moment from 'moment'
import { UtilisateurContext } from '../../auth/UtilisateurContextProvider'
import { GetLastYearCalendrier } from '../../firebaseManager/Communicator/CalendrierUsageCommunication'

export type Props = {}

type historique = {
    month: number
    year: number
    count: number
}

export const GraphiqueCalendrierVelotaf = (props: Props) => {
    const authentification = useContext(AuthContext)
    const utilisateur = useContext(UtilisateurContext)

    const [historiqueCalendier, setHistoriqueCalendier] = useState<
        historique[] | null | undefined
    >([])
    function refresh12DerniersCalendriers() {
        if (utilisateur) {
            GetLastYearCalendrier(utilisateur.id)
                .then((result) => {
                    let calendierVelotafLoaded: historique[] = []
                    if (result) {
                        result.forEach((calendrier) => {
                            const count =
                                calendrier.usage.filter(Boolean).length
                            const month = calendrier.month
                            const year = calendrier.year

                            calendierVelotafLoaded =
                                calendierVelotafLoaded.concat({
                                    month: month,
                                    year: year,
                                    count: count,
                                })
                        })
                    }

                    let calendierVelotafFinal: historique[] = []

                    for (let i = 11; i >= 0; i--) {
                        const target = moment().subtract(i, 'months')

                        const calendrierCible: historique | undefined =
                            calendierVelotafLoaded.find(
                                (calendrier: historique) =>
                                    calendrier.month === target.month() &&
                                    calendrier.year === target.year()
                            )
                        if (calendrierCible) {
                            calendierVelotafFinal =
                                calendierVelotafFinal.concat(calendrierCible)
                        } else {
                            calendierVelotafFinal =
                                calendierVelotafFinal.concat({
                                    month: target.month(),
                                    year: target.year(),
                                    count: 0,
                                })
                        }
                    }

                    calendierVelotafFinal.sort((a, b) => {
                        if (a.month < b.month) {
                            return -1
                        }
                        if (a.month > b.month) {
                            return 1
                        }
                        return 0
                    })
                    calendierVelotafFinal.sort((a, b) => {
                        if (a.year < b.year) {
                            return -1
                        }
                        if (a.year > b.year) {
                            return 1
                        }
                        return 0
                    })
                    setHistoriqueCalendier(calendierVelotafFinal)
                })
                .catch((e) => {
                    console.log(e)

                    setHistoriqueCalendier(null)
                })
        }
    }

    useEffect(() => {
        refresh12DerniersCalendriers()
    }, [authentification, utilisateur])

    function dateFormatter(month: number) {
        return moment()
            .set({ month: month, date: 1 })
            .format('MMMM')
            .replace(/^./, (str) => str.toUpperCase())
    }

    function CustomTooltip({ active, payload, label }: any) {
        if (active && payload && payload.length) {
            return (
                <Box
                    className="custom-tooltip"
                    padding={'10px'}
                    borderRadius={'10px'}
                    backgroundColor={'white'}
                    color={'black'}
                    border={'solid 1px #285e78'}
                >
                    <Center>{dateFormatter(label)}</Center>
                    <Center color={'#31a1d7'}>
                        {' '}
                        <b>{payload[0].value} trajets</b>
                    </Center>
                </Box>
            )
        }

        return null
    }

    return (
        <>
            <Center>
                <Text as={'b'} color={'black'} fontSize="md">
                    Bilan de vos trajets des douze derniers mois
                </Text>
            </Center>
            {historiqueCalendier !== null &&
                historiqueCalendier !== undefined &&
                historiqueCalendier.length > 0 && (
                    <ResponsiveContainer>
                        <BarChart
                            data={historiqueCalendier}
                            margin={{ right: 0, left: -30, bottom: 10 }}
                        >
                            <Bar dataKey="count" fill="#2d7bb6" />

                            {/**    XAxis ajouter scale="time" si on veux une adaptation par rapport au temps
                             */}
                            <XAxis
                                dataKey="month"
                                tickFormatter={dateFormatter}
                            />
                            <YAxis
                                type="number"
                                dataKey="count"
                                domain={[
                                    0,
                                    Math.max(
                                        ...historiqueCalendier.map(function (
                                            histo
                                        ) {
                                            return histo.count
                                        }),
                                        10
                                    ),
                                ]}
                            />
                            <Tooltip
                                content={<CustomTooltip />}
                                cursor={{ fill: 'transparent' }}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                )}

            {historiqueCalendier === undefined ||
                (historiqueCalendier !== null &&
                    historiqueCalendier !== undefined &&
                    historiqueCalendier.length === 0 && (
                        <>
                            <Center mt="70px">
                                <Text as="b">
                                    Données indisponibles pour le graphique.
                                </Text>
                            </Center>
                        </>
                    ))}

            {historiqueCalendier === null && (
                <>
                    <Center mt="40px">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="white"
                            color="black"
                            size="xl"
                        />{' '}
                    </Center>
                    <Center mt="20px">
                        <Text as="b">Chargement des données du graphique</Text>
                    </Center>
                </>
            )}
        </>
    )
}
