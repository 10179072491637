/*
 * Pied de page général du site
 */
import {
    Box,
    Text,
    SimpleGrid,
    Spinner,
    Tooltip,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Stack,
    useDisclosure,
    Spacer,
    Flex,
} from '@chakra-ui/react'
import { useState, useContext, useEffect, ReactElement } from 'react'
import moment, { Moment } from 'moment'
import { GetMonthCalendrierAggregated } from '../../../firebaseManager/Communicator/CalendrierUsageCommunication'
import { UtilisateurContext } from '../../../auth/UtilisateurContextProvider'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    WarningIcon,
} from '@chakra-ui/icons'
import { Button as ButtonCustom } from '../../Button'
import {
    CalendrierUsageAggregateDetail,
    CalendrierUsageAggregate,
} from '../../../model'
import { ExportActionButton } from '../../ExportActionButton'

export type PropsBoxDay = {
    name: string
}
const BoxDay = ({ name }: PropsBoxDay) => {
    return (
        <Box p={'2px'} pt={'7px'} textAlign={'center'} as="b">
            {name}
        </Box>
    )
}

const BoxVide = () => {
    return <Box p={'2px'} pt={'7px'} textAlign={'center'}></Box>
}

export type PropBoxDaySelectionnable = {
    name: string
    index: number
    utilise: number
    maxUsage: number
    details?: CalendrierUsageAggregateDetail[]
}
const BoxDaySelectionnable = ({
    name,
    index,
    utilise,
    maxUsage,
    details,
}: PropBoxDaySelectionnable) => {
    const ratioColor = Math.round((utilise / maxUsage) * 9) * 100
    const tooltipBase = utilise + ' utilisation' + (utilise > 1 ? 's' : '')
    const tooltip = ConstructTooltip(tooltipBase, index, details)
    return (
        <Tooltip hasArrow label={tooltip} aria-label="A tooltip">
            <Box
                bgColor={utilise > 0 ? 'blue.' + ratioColor : 'gray.200'}
                color={ratioColor > 300 ? 'white' : 'blue.800'}
                h={'40px'}
                w={'40px'}
                p={'2px'}
                pt={'7px'}
                textAlign={'center'}
                borderRadius={'10px'}
            >
                {name}
            </Box>
        </Tooltip>
    )
}

const ConstructTooltip = (
    tooltipBase: string,
    index: number,
    details?: CalendrierUsageAggregateDetail[]
): ReactElement => {
    let component = (
        <Stack>
            <Text>{tooltipBase}</Text>
            {details?.map(function (detail, idx) {
                if (
                    index <= detail.usage.length &&
                    detail.usage[index] === true
                ) {
                    return <Text key={idx}>{detail.nom}</Text>
                }
            })}
        </Stack>
    )
    return component
}

export type Props = {
    targetDay: Moment
    entrepriseId?: string
    agenceId?: string
}

export const CalendrierUsageAggregatedViewer = ({
    targetDay,
    entrepriseId,
    agenceId,
}: Props) => {
    const utilisateur = useContext(UtilisateurContext)

    const numberday = targetDay.daysInMonth()
    const offset =
        targetDay.startOf('month').day() != 0
            ? targetDay.startOf('month').day() - 1
            : 6
    const offsetDay = new Array<Boolean>(offset).fill(true)

    const [usage, setUsage] = useState<number[]>([])

    const [calendrierUsageAggregate, setCalendrierUsageAggregate] = useState<
        CalendrierUsageAggregate | undefined
    >()

    const [details, setDetails] = useState<CalendrierUsageAggregateDetail[]>([])
    const [maxUsage, setMaxUsage] = useState<number>(0)
    const [usagePannelLoading, setUsagePannelLoading] = useState<boolean>(false)
    const [usagePannelLoaded, setUsagePannelLoaded] = useState<boolean>(false)
    const [usagePannelError, setUsagePannelError] = useState<boolean>(false)

    useEffect(() => {
        if (utilisateur != null) {
            setUsagePannelLoaded(false)
            setUsagePannelLoading(true)
            setUsagePannelError(false)
            GetMonthCalendrierAggregated(
                targetDay.month(),
                targetDay.year(),
                entrepriseId,
                agenceId
            )
                .then((result) => {
                    if (result) {
                        setUsage(result.usage)
                        setMaxUsage(Math.max(...result.usage))
                        setCalendrierUsageAggregate(result)
                        if (result.details) {
                            setDetails(result.details)
                        }
                    } else {
                        setUsage([])
                        setDetails([])
                        setCalendrierUsageAggregate(undefined)
                        setUsagePannelError(true)
                    }
                    setUsagePannelLoading(false)
                    setUsagePannelLoaded(true)
                })
                .catch((e) => {
                    setUsage([])
                    setDetails([])
                    setCalendrierUsageAggregate(undefined)
                    setUsagePannelLoading(false)
                    setUsagePannelLoaded(true)
                    setUsagePannelError(true)
                })
        }
    }, [utilisateur, targetDay, entrepriseId, agenceId])

    let listNumber: string[] = []
    for (let i = 1; i < numberday + 1; i++) {
        listNumber.push(i + '')
    }
    return (
        <>
            {usagePannelLoading && (
                <Box textAlign="center" py={10} px={6}>
                    <Spinner
                        mt={'50px'}
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                    <Text color={'gray.500'} mt={3} mb={2}>
                        Chargement du calendrier ...
                    </Text>
                </Box>
            )}
            {usagePannelError && (
                <Box textAlign="center" py={10} px={6}>
                    <WarningIcon
                        mt={'50px'}
                        color={'orange.400'}
                        fontSize={'35px'}
                    />
                    <Text color={'gray.500'} mt={3} mb={2}>
                        Aucune donnée à afficher pour ce mois
                    </Text>
                </Box>
            )}
            {!usagePannelError && usagePannelLoaded && (
                <>
                    <ExportActionButton
                        calendrierUsageAggregate={calendrierUsageAggregate}
                    />
                    <SimpleGrid
                        columns={7}
                        spacing="3px"
                        w={'320px'}
                        ml={'calc((100% - 320px)/2) '}
                    >
                        <BoxDay name="Lun" />
                        <BoxDay name="Mar" />
                        <BoxDay name="Mer" />
                        <BoxDay name="Jeu" />
                        <BoxDay name="Ven" />
                        <BoxDay name="Sam" />
                        <BoxDay name="Dim" />

                        {offsetDay.map((bool, i) => {
                            return <BoxVide key={i} />
                        })}
                        {listNumber.map((number, i) => {
                            return (
                                <BoxDaySelectionnable
                                    key={i}
                                    name={number}
                                    index={i}
                                    utilise={usage[i]}
                                    maxUsage={maxUsage}
                                    details={details}
                                />
                            )
                        })}
                    </SimpleGrid>
                </>
            )}
        </>
    )
}

export type PropsCalendrierUsageBox = {
    entrepriseId?: string
    agenceId?: string
}

export const ModalCalendrierAggregatedViewer = ({
    entrepriseId,
    agenceId,
}: PropsCalendrierUsageBox) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [targetMonth, setTargetMonth] = useState<Moment>(moment())

    const lastmonth = targetMonth.clone().subtract(1, 'months')
    const nextmonth = targetMonth.clone().add(1, 'months')
    return (
        <>
            <ButtonCustom onClick={onOpen}>Voir usage</ButtonCustom>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Usage :{' '}
                        {targetMonth
                            .format('MMMM YYYY')
                            .replace(/^./, (str) => str.toUpperCase())}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box h={'360px'}>
                            <CalendrierUsageAggregatedViewer
                                targetDay={targetMonth}
                                entrepriseId={
                                    agenceId ? undefined : entrepriseId
                                }
                                agenceId={agenceId}
                            />
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Flex w={'100%'}>
                            <Button
                                onClick={(e) => {
                                    setTargetMonth(lastmonth)
                                }}
                            >
                                <ChevronLeftIcon fontSize={'2xl'} />
                                {lastmonth.format('MMMM YYYY')}
                            </Button>
                            <Spacer />

                            <Button
                                hidden={nextmonth > moment()}
                                onClick={(e) => {
                                    setTargetMonth(nextmonth)
                                }}
                            >
                                {nextmonth.format('MMMM YYYY')}
                                <ChevronRightIcon fontSize={'2xl'} />
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
