/*
 * Class d'intération avec firestore pour les éléments : Agence
 */
import { CalendrierUsage, CalendrierUsageAggregate } from '../../../model'
import { db } from '../..'
import {
    collection,
    query,
    getDocs,
    where,
    limit,
    orderBy,
} from 'firebase/firestore'

export async function GetMonthCalendrier(
    utilisateurId: string,
    month: number,
    year: number
): Promise<CalendrierUsage | undefined> {
    const q = query(
        collection(db, 'calendrierUsage'),
        where('year', '==', year),
        where('month', '==', month),
        where('utilisateurAppartenance', '==', utilisateurId),
        orderBy('dateOfLastUpdate', 'desc'),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as CalendrierUsage
    }
    return undefined
}

export async function GetMonthCalendrierAggregated(
    month: number,
    year: number,
    entrepriseId: string | undefined,
    agenceId: string | undefined
): Promise<CalendrierUsageAggregate | undefined> {
    let q = query(
        collection(db, 'calendrierUsageAggregate'),
        where('year', '==', year),
        where('month', '==', month)
    )
    if (entrepriseId) {
        q = query(q, where('entrepriseAppartenance', '==', entrepriseId))
    }
    if (agenceId) {
        q = query(q, where('agenceAppartenance', '==', agenceId))
    }
    query(q, limit(1))

    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as CalendrierUsageAggregate
    }

    return undefined
}

export async function GetLastYearCalendrier(
    utilisateurId: string
): Promise<CalendrierUsage[] | undefined> {
    const q = query(
        collection(db, 'calendrierUsage'),
        where('utilisateurAppartenance', '==', utilisateurId),
        orderBy('dateOfLastUpdate', 'desc'),
        limit(12)
    )

    const querySnapshot = await getDocs(q)
    const lastYearCalendriers: CalendrierUsage[] = querySnapshot.docs.map(
        (doc) => {
            return { id: doc.id, ...doc.data() } as CalendrierUsage
        }
    )
    return lastYearCalendriers
}
