/*
 * Pied de page général du site
 */
import { Box, Center, Text, Wrap } from '@chakra-ui/react'
import { CustomBox } from '../../CustomBox'
import moment from 'moment'
import { EntrepriseContext } from '../../../auth/EntrepriseContextProvider'
import { useContext } from 'react'
import { GraphiqueCalendrierVelotaf } from '../../GraphiqueCalendrierVelotaf'
import { UtilisateurContext } from '../../../auth/UtilisateurContextProvider'
import { CompteurObjectifPourcentage } from '../../../utils/helpers'

// affichage via BOX
export type PropsBlocStatCalendierUtilisateur = {}

export const BlocStatCalendierUtilisateur =
    ({}: PropsBlocStatCalendierUtilisateur) => {
        const entreprise = useContext(EntrepriseContext)
        const utilisateur = useContext(UtilisateurContext)

        return (
            <Wrap mt={'20px'} mb={'20px'}>
                <Box
                    w={{ base: '100%', md: '260px' }}
                    pt={{ base: '0px', md: '20px' }}
                    pb={{ base: '10px', md: '0px' }}
                >
                    <Center>
                        <CustomBox
                            w={'190px'}
                            h={'190px'}
                            borderRadius={'15px'}
                            padding={'10px'}
                            display={'block'}
                            color={'black'}
                        >
                            <Center>
                                <Text as={'b'} fontSize="md">
                                    Objectif annuel
                                </Text>
                            </Center>
                            <Center mt={'10px'}>
                                <svg
                                    viewBox="100 100 385 310"
                                    height="90px"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <defs></defs>
                                    <path
                                        fill="rgb(216, 216, 216)"
                                        stroke="rgb(0, 0, 0)"
                                        strokeWidth={'15px'}
                                        d="M 362.409 186.633 L 130.357 324.407"
                                    ></path>
                                    <text
                                        fill="#2d7bb6"
                                        fontSize={'141px'}
                                        fontWeight={'700'}
                                        strokeWidth={'6px'}
                                        x="94.36"
                                        y="208.427"
                                    >
                                        {utilisateur?.usageCetteAnnee
                                            ? utilisateur.usageCetteAnnee
                                            : '0'}
                                    </text>
                                    <text
                                        fill="rgb(51, 51, 51)"
                                        fontSize={'141px'}
                                        fontWeight={'700'}
                                        strokeWidth={'6px'}
                                        x="236.339"
                                        y="394.221"
                                    >
                                        {entreprise?.objectif}
                                    </text>
                                </svg>
                            </Center>
                            <Center mt={'10px'}>
                                <Text
                                    as={'b'}
                                    fontSize="10px"
                                    textAlign={'center'}
                                >
                                    Soit{' '}
                                    {CompteurObjectifPourcentage(
                                        utilisateur?.usageCetteAnnee,
                                        entreprise?.objectif
                                    )}{' '}
                                    % de l'objectif (réactualisé toutes les
                                    nuits)
                                </Text>
                            </Center>
                        </CustomBox>
                    </Center>
                </Box>
                <CustomBox
                    w={{ base: '100%', md: '468px' }}
                    h={{ base: '230px', md: '230px' }}
                    borderRadius={'15px'}
                    padding={'10px'}
                    display={'block'}
                    color={'white'}
                >
                    <GraphiqueCalendrierVelotaf />
                </CustomBox>
            </Wrap>
        )
    }
